import { Form } from "@remix-run/react";
import { FcGoogle } from "react-icons/fc";
import Header from "~/components/Header";

// export const loader: LoaderFunction = async ({ request, params }) => {
//   const url = new URL(request.url);

//   const redirectTo = url.searchParams.get("redirectTo");
//   return { redirectTo };
// };

export default function Login() {
  // const data = useLoaderData();

  return (
    <div className=" lg:max-w-6xl mx-auto">
      <Header schoolYear="Reports" />
      <main className="bg-white min-h-screen">
        <div>
          <Form action="../auth/google" method="post">
            <div className="mx-auto pt-12 relative">
              <h3 className="text-center font-semibold text-xl">
                Amarillo ISD Staff
              </h3>
              <p className="text-sm text-center w-3/4 mx-auto">
                Click the button below to log in with your{" "}
                <span className="font-semibold">school Google Account</span> to
                view New Student Registrations{" "}
              </p>
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 mt-36 ">
                <button
                  type="submit"
                  className="rounded-lg border border-gray-600 p-6 shadow-md font-semibold shadow-blue-200 transition-transform duration-500 transform hover:scale-110"
                >
                  <FcGoogle className=" h-12 w-3/4 mx-auto mb-2" /> Login with
                  Google
                </button>
              </div>
            </div>
          </Form>
        </div>
      </main>
    </div>
  );
}
